import {gql} from '@apollo/client'

export const MANAGE_CREATE_COMPANY_MUTATION = gql`
  mutation createCompany($name: String!, $address1: String, $address2: String, $city: String, $country: String, $postcode: String, $logoFile: Upload) {
    createCompany(input: { name: $name, address1: $address1, address2: $address2, city: $city, country: $country, postcode: $postcode, logoFile: $logoFile}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_COMPANY_MUTATION = gql`
  mutation updateCompany($id: ID!, $name: String!, $address1: String, $address2: String, $city: String, $country: String, $postcode: String, $logoFile: Upload, $licensingSettings: [InputCreateCompanyLicensingSetting!]) {
    updateCompany(input: { id: $id, name: $name, address1: $address1, address2: $address2, city: $city, country: $country, postcode: $postcode, logoFile: $logoFile, licensingSettings: $licensingSettings }) {
      success
      message
    }
  }
`

export const MANAGE_SUSPEND_COMPANY_MUTATION = gql`
  mutation suspendCompany($id: ID!, $comment: String!) {
    suspendCompany(input: { id: $id, comment: $comment}) {
      success
      message
    }
  }
`

export const MANAGE_RESUME_COMPANY_MUTATION = gql`
  mutation resumeCompany($id: ID!) {
    resumeCompany(input: { id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_COMPANY_MUTATION = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(input: { id: $id}) {
      success
      message
    }
  }
`
export const MANAGE_UPLOAD_COMPANY_LOGO_MUTATION = gql`
  mutation uploadCompanyLogo($id: ID!, $file: Upload!) {
    uploadCompanyLogo(input: {id: $id, file: $file}) {
      success
      message
    }
  }
`


export const MANAGE_INVITE_USER_MUTATION = gql`
  mutation inviteUser($email: String!, $roles: [RoleInput!]! ) {
    inviteUser(input: { email: $email, roles: $roles }) {
      success
      message
    }
  }
`

export const MANAGE_ACCEPT_INVITE_USER_MUTATION = gql`
  mutation acceptUserInvitation($email: String!, $newPassword: String!, $firstName: String!, $lastName: String!, $telephone: String,  $password: String!, ) {
    acceptUserInvitation(input: { email: $email, newPassword: $newPassword, firstName: $firstName, lastName: $lastName, telephone: $telephone, password: $password}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_USER_MUTATION = gql`
  mutation updateUser($id: ID!, $email: String!, $firstName: String!, $lastName: String!,$telephone: String,  $roles: [RoleInput!]! ) {
    updateUser(input: {id: $id, email: $email,firstName: $firstName, lastName: $lastName, roles: $roles, telephone: $telephone}) {
      success
      message
    }
  }
`

export const MANAGE_ENABLE_USER_MUTATION = gql`
  mutation enableUser($id: ID!) {
    enableUser(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_DISABLE_USER_MUTATION = gql`
  mutation disableUser($id: ID!, $reason: String!) {
    disableUser(input: {id: $id, reason: $reason}) {
      success
      message
    }
  }
`

export const MANAGE_RE_INVITE_USER_MUTATION = gql`
  mutation reInviteUser($id: ID!) {
    reInviteUser(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: ID!, $companyId: ID) {
    deleteUser(input: { id: $id, companyId: $companyId}) {
      success
      message
    }
  }
`


export const MANAGE_DELETE_USER_PERMISSION_MUTATION = gql`
  mutation deleteUserPermission($id: ID!, $permissionId: ID!) {
    deleteUserPermission(input: { id: $id, permissionId: $permissionId}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_SITE_MUTATION = gql`
  mutation createSite($companyId: ID!, $name: String!, $description: String, $address: String, $postcode: String, $lat: Float, $lng: Float, $defaultZoom: Int, $isWeeklySystemCheckEnabled: Boolean,
    $isIntradaySystemCheckEnabled: Boolean, $intradaySystemCheckShifts: [InputSystemCheckShift]) {
    createSite(input: { companyId: $companyId, name: $name,description: $description, address: $address, postcode: $postcode, lat: $lat, lng: $lng, defaultZoom: $defaultZoom, isWeeklySystemCheckEnabled: $isWeeklySystemCheckEnabled, isIntradaySystemCheckEnabled: $isIntradaySystemCheckEnabled, intradaySystemCheckShifts: $intradaySystemCheckShifts }) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_SITE_MUTATION = gql`
  mutation updateSite($id: ID!, $companyId: ID!, $name: String!, $address: String,  $description: String, $postcode: String, $lat: Float, $lng: Float, $defaultZoom: Int, $isWeeklySystemCheckEnabled: Boolean,
    $isIntradaySystemCheckEnabled: Boolean, $intradaySystemCheckShifts: [InputSystemCheckShift]) {
    updateSite(input: { id: $id, companyId: $companyId, name: $name, description: $description, address: $address, postcode: $postcode, lat: $lat, lng: $lng, defaultZoom: $defaultZoom, isWeeklySystemCheckEnabled: $isWeeklySystemCheckEnabled, isIntradaySystemCheckEnabled: $isIntradaySystemCheckEnabled, intradaySystemCheckShifts: $intradaySystemCheckShifts }) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_SITE_MUTATION = gql`
  mutation deleteSite($id: ID!) {
    deleteSite(input: { id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_ZONE_MUTATION = gql`
  mutation createZone($siteId: ID!, $name: String!, $type: String!, $floorPlanFile: Upload, $isWeeklySystemCheckEnabled: Boolean, $isIntradaySystemCheckEnabled: Boolean, $intradaySystemCheckShifts: [InputSystemCheckShift]) {
    createZone(input: { siteId: $siteId, name: $name, type: $type, floorPlanFile: $floorPlanFile, isWeeklySystemCheckEnabled: $isWeeklySystemCheckEnabled, isIntradaySystemCheckEnabled: $isIntradaySystemCheckEnabled, intradaySystemCheckShifts: $intradaySystemCheckShifts }) {
      success
       message
    }
  }
`

export const MANAGE_UPDATE_ZONE_MUTATION = gql`
  mutation updateZone($id: ID!, $siteId: ID!, $name: String!, $type: String!, $floorPlanFile: Upload, $isWeeklySystemCheckEnabled: Boolean, $isIntradaySystemCheckEnabled: Boolean, $intradaySystemCheckShifts: [InputSystemCheckShift]) {
    updateZone(input: {id: $id, siteId: $siteId, name: $name, type: $type, floorPlanFile: $floorPlanFile, isWeeklySystemCheckEnabled: $isWeeklySystemCheckEnabled, isIntradaySystemCheckEnabled: $isIntradaySystemCheckEnabled, intradaySystemCheckShifts: $intradaySystemCheckShifts }) {
      success
       message
    }
  }
`

export const MANAGE_UPDATE_ZONE_FLOOR_PLAN_MUTATION = gql`
  mutation updateZoneFloorPlan($id: ID!, $floorPlanFile: Upload) {
    updateZoneFloorPlan(input: {id: $id, floorPlanFile: $floorPlanFile }) {
      success
       message
    }
  }
`

export const MANAGE_DELETE_ZONE_MUTATION = gql`
  mutation deleteZone($id: ID!) {
    deleteZone(input: { id: $id}) {
      success
      message
    }
  }
`
export const MANAGE_CREATE_ASSET_MUTATION = gql`
  mutation createAsset($zoneId: ID!, $name: String!, $type: String!, $driverId: ID, $assetProfile: AddAssetProfileInput) {
    createAsset(input: { zoneId: $zoneId, name: $name, type: $type, driverId: $driverId, assetProfile: $assetProfile }) {
      success
    }
  }
`
export const MANAGE_UPDATE_ASSET_MUTATION = gql`
  mutation updateAsset($id: ID!, $zoneId: ID!, $name: String!, $deviceAssets: [AssetDeviceInput], $type: String!, $forceCloseOpenedIncidents: Boolean,  $driverId: ID, $assetProfile: UpdateAssetProfileInput) {
    updateAsset(input: { id: $id, zoneId: $zoneId, name: $name, deviceAssets: $deviceAssets, type: $type, forceCloseOpenedIncidents: $forceCloseOpenedIncidents, driverId: $driverId, assetProfile: $assetProfile}) {
       success
       message
    }
  }
`

export const MANAGE_ADD_ASSET_DEVICES_MUTATION = gql`
  mutation addAssetDevices($assetId: ID!, $deviceProperties: [AddAssetDeviceInput!]!) {
    addAssetDevices(input: {assetId: $assetId, deviceProperties: $deviceProperties}) {
      success
       message
    }
  }
`

export const MANAGE_DELETE_ASSET_DEVICE_MUTATION = gql`
  mutation deleteAssetDevice($assetDevice: DeleteAssetDeviceInput!) {
    deleteAssetDevice(input: $assetDevice) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_ASSET_MUTATION = gql`
  mutation deleteAsset($id: ID!) {
    deleteAsset(input: { id: $id}) {
      success
      message
    }
  }
`
export const MANAGE_CREATE_DEVICE_MUTATION = gql`
  mutation createDevice($companyId: ID!, $name: String!, $serial: String!,  $typeId: ID!, $relationship: String!, $parentAssetId: ID, $joinEui: String, $appKey: String) {
    createDevice(input: { companyId: $companyId, name: $name, serial: $serial, typeId: $typeId, relationship: $relationship, parentAssetId: $parentAssetId, joinEui: $joinEui, appKey: $appKey}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_BULK_DEVICE_MUTATION = gql`
  mutation createBulkDevices($devices: [CreateDeviceInput!] ) {
    createBulkDevices(input: { devices : $devices}) {
      success
      message
      failedDevices {
        name
        serial
        typeId
        companyId
      }
    }
  }
`

export const MANAGE_UPDATE_DEVICE_MUTATION = gql`
  mutation updateDevice($id: ID!, $name: String!, $typeId: ID!, $serial: String!, $deviceAssets: [AssetDeviceInput], $forceCloseOpenedIncidents: Boolean, $relationship: String!, $parentAssetId: ID, $configurations: [DeviceConfigurationInput], $joinEui: String, $appKey: String) {
    updateDevice(input: {id: $id, name: $name, typeId: $typeId, serial: $serial, deviceAssets: $deviceAssets, forceCloseOpenedIncidents: $forceCloseOpenedIncidents, relationship: $relationship, parentAssetId: $parentAssetId, configurations: $configurations, joinEui: $joinEui, appKey: $appKey}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_DEVICE_MUTATION = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(input: { id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_IMPORT_BULK_DEVICE_CERTIFICATES_MUTATION = gql`
  mutation importDeviceCertificates($companyId: ID!, $certificates: [CreateDeviceCertificateInput!]! ) {
    importDeviceCertificates(input: { companyId: $companyId, certificates : $certificates}) {
      success
      message
      failedCertificates {
        name
        serial
        issueDate
        expireDate
        message
      }
    }
  }
`


export const MANAGE_CREATE_DEVICE_TYPE_MUTATION = gql`
  mutation createDeviceType($name: String!, $properties: [ID], $configurations: [ID]) {
    createDeviceType(input: { name: $name, properties: $properties, configurations: $configurations}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_DEVICE_TYPE_MUTATION = gql`
  mutation updateDeviceType($id: ID!, $name: String!, $properties: [ID], $configurations: [ID]) {
    updateDeviceType(input: {id: $id, name: $name, properties: $properties, configurations: $configurations}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_DEVICE_TYPE_MUTATION = gql`
  mutation deleteDeviceType($id: ID!) {
    deleteDeviceType(input: {id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_CREATE_DEVICE_PROPERTY_MUTATION = gql`
  mutation createDeviceProperty($name: String!, $unit: String, $messageType: String!) {
    createDeviceProperty(input: { name: $name, unit: $unit, messageType: $messageType}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_DEVICE_PROPERTY_MUTATION = gql`
  mutation updateDeviceProperty($id: ID!, $name: String!, $unit: String, $messageType: String!) {
    updateDeviceProperty(input: {id: $id, name: $name, unit: $unit, messageType: $messageType}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_DEVICE_PROPERTY_MUTATION = gql`
  mutation deleteDeviceProperty($id: ID!) {
    deleteDeviceProperty(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_DEVICE_PROPERTY_GROUP_MUTATION = gql`
  mutation createDeviceProperty($name: String!, $companyId: ID!, $isShowInReport: Boolean!, $isWarning: Boolean!, $ackRequired: Boolean!, $correctiveRequired: Boolean!, $rootRequired: Boolean!, $preventativeRequired: Boolean!, $optimalLow: Float!, $optimalHigh: Float!,
  $alarmLow: Float!, $alarmHigh: Float!, $isIncident: Boolean!, $waitOpen: Int, $waitClose: Int, $offlineEnabled: Boolean, $deadman: Int, $completenessEnabled: Boolean, $completenessInterval: Int, $type: String!, $unitPriceEnabled: Boolean,  $unitPrice: Float, $ignoreAtDepot: Boolean!) {
    createDevicePropertyGroup(input: { name: $name, companyId: $companyId, isShowInReport: $isShowInReport,
    isWarning: $isWarning, ackRequired: $ackRequired, correctiveRequired: $correctiveRequired, rootRequired: $rootRequired, preventativeRequired: $preventativeRequired, optimalLow: $optimalLow, optimalHigh: $optimalHigh,
  alarmLow: $alarmLow, alarmHigh: $alarmHigh, isIncident: $isIncident, waitOpen: $waitOpen, waitClose: $waitClose, offlineEnabled: $offlineEnabled, deadman: $deadman,
  completenessEnabled: $completenessEnabled, completenessInterval: $completenessInterval, type: $type, unitPriceEnabled: $unitPriceEnabled, unitPrice: $unitPrice, ignoreAtDepot: $ignoreAtDepot }) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_DEVICE_PROPERTY_GROUP_MUTATION = gql`
  mutation updateDevicePropertyGroup($id: ID!, $name: String!, $isShowInReport: Boolean!, $optimalLow: Float!, $optimalHigh: Float!,
  $alarmLow: Float!, $alarmHigh: Float!, $isIncident: Boolean!, $isWarning: Boolean!, $ackRequired: Boolean!, $correctiveRequired: Boolean!, $rootRequired: Boolean!, $preventativeRequired: Boolean!, $waitOpen: Int, $waitClose: Int, $offlineEnabled: Boolean, $deadman: Int, $completenessEnabled: Boolean, $completenessInterval: Int, $type: String!, $unitPriceEnabled: Boolean, $unitPrice: Float,  $ignoreAtDepot: Boolean!, $acknowledgedUpdateWarning: Boolean! ) {
    updateDevicePropertyGroup(input: {id: $id, name: $name, isShowInReport: $isShowInReport, optimalLow: $optimalLow, optimalHigh: $optimalHigh,
  alarmLow: $alarmLow, alarmHigh: $alarmHigh, isIncident: $isIncident, isWarning: $isWarning, ackRequired: $ackRequired, correctiveRequired: $correctiveRequired, rootRequired: $rootRequired, preventativeRequired: $preventativeRequired, waitOpen: $waitOpen, waitClose: $waitClose,offlineEnabled :$offlineEnabled, deadman: $deadman, completenessEnabled: $completenessEnabled,
        completenessInterval: $completenessInterval, type: $type, unitPriceEnabled: $unitPriceEnabled, unitPrice: $unitPrice, ignoreAtDepot: $ignoreAtDepot, acknowledgedUpdateWarning: $acknowledgedUpdateWarning}) {
      success
      message
      dependentAssetList
    }
  }
`

export const MANAGE_DELETE_DEVICE_PROPERTY_GROUP_MUTATION = gql`
  mutation deleteDevicePropertyGroup($id: ID!, $forceCloseOpenedIncidents: Boolean) {
    deleteDevicePropertyGroup(input: {id: $id, forceCloseOpenedIncidents: $forceCloseOpenedIncidents}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_KPI_SETTING_GROUPS_MUTATION = gql`
  mutation createKpiSettingGroup($name: String!) {
    createKpiSettingGroup(input: { name: $name}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_KPI_SETTING_GROUP_MUTATION = gql`
  mutation updateKpiSettingGroup($id: ID!, $name: String!) {
    updateKpiSettingGroup(input: { id: $id, name: $name}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_KPI_SETTING_GROUP_MUTATION = gql`
  mutation deleteKpiSettingGroup($id: ID!) {
    deleteKpiSettingGroup(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_KPI_SETTINGS_MUTATION = gql`
  mutation createKpiSetting($name: String!, $companyId: ID!, $groupId: ID!, $valueLow: Int!, $valueHigh: Int!,  $icon: String) {
    createKpiSetting(input: { name: $name,  companyId: $companyId,  groupId: $groupId, valueLow: $valueLow, valueHigh: $valueHigh, icon: $icon}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_KPI_SETTINGS_MUTATION = gql`
  mutation updateKpiSetting($id: ID!, $name: String!, $companyId: ID!,  $groupId: ID!, $valueLow: Int!, $valueHigh: Int!, $icon: String) {
    updateKpiSetting(input: { id: $id, name: $name, companyId: $companyId, groupId: $groupId, valueLow: $valueLow, valueHigh: $valueHigh, icon: $icon}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_KPI_SETTINGS_MUTATION = gql`
  mutation deleteKpiSetting($id: ID!) {
    deleteKpiSetting(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_TIME_PROFILE_MUTATION = gql`
  mutation createTimeProfile($name: String!, $companyId: ID!, $shifts: [TimeProfileShiftInput] ) {
    createTimeProfile(input: {name: $name, companyId: $companyId, shifts: $shifts}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_TIME_PROFILE_MUTATION = gql`
  mutation updateTimeProfile($id: ID!, $name: String!, $companyId: ID!, $shifts: [TimeProfileShiftInput] ) {
    updateTimeProfile(input: { id: $id, name: $name, companyId: $companyId, shifts: $shifts}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_TIME_PROFILE_MUTATION = gql`
  mutation deleteTimeProfile($id: ID!) {
    deleteTimeProfile(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_NOTIFICATION_MESSAGE_TYPE_MUTATION = gql`
  mutation createNotificationMessageType($name: String!, $label: String!) {
    createNotificationMessageType(input: { name: $name, label: $label}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_NOTIFICATION_MESSAGE_TYPE_MUTATION = gql`
  mutation updateNotificationMessageType($id: ID!, $name: String!, $label: String!) {
    updateNotificationMessageType(input: { id: $id, name: $name, label: $label}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_NOTIFICATION_MESSAGE_TYPE_MUTATION = gql`
  mutation deleteNotificationMessageType($id: ID!) {
    deleteNotificationMessageType(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_NOTIFICATION_PROFILE_MUTATION = gql`
  mutation createNotificationProfile($name: String!, $companyId: ID!, $timeProfileId: ID!, $incidentTypes : [String]! ) {
    createNotificationProfile(input: {name: $name, companyId: $companyId, timeProfileId: $timeProfileId, incidentTypes: $incidentTypes}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_NOTIFICATION_PROFILE_MUTATION = gql`
  mutation updateNotificationProfile($id: ID!, $name: String!, $companyId: ID!, $timeProfileId: ID!, $incidentTypes : [String]! ) {
    updateNotificationProfile(input: { id: $id, name: $name, companyId: $companyId, timeProfileId: $timeProfileId, incidentTypes: $incidentTypes}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_NOTIFICATION_PROFILE_MUTATION = gql`
  mutation deleteNotificationProfile($id: ID!) {
    deleteNotificationProfile(input: {id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_CREATE_NOTIFICATION_PROFILE_MESSAGE_MUTATION = gql`
  mutation createNotificationProfileMessage($profileMessage: InputCreateNotificationProfileMessage!) {
    createNotificationProfileMessage(input: $profileMessage) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_NOTIFICATION_PROFILE_MESSAGE_MUTATION = gql`
  mutation updateNotificationProfileMessage($profileMessage: InputUpdateNotificationProfileMessage!) {
    updateNotificationProfileMessage(input: $profileMessage) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_NOTIFICATION_PROFILE_MESSAGE_MUTATION = gql`
  mutation deleteNotificationProfileMessage($id: ID!) {
    deleteNotificationProfileMessage(input: {id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_NOTIFICATION_PROFILE_ASSETS_MUTATION = gql`
  mutation updateNotificationProfileAssets($notificationProfileId: ID!, $assetIds: [ID!]) {
    updateNotificationProfileAssets(input: { notificationProfileId : $notificationProfileId, assetIds: $assetIds }) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_NOTIFICATION_PROFILE_ASSET_MUTATION = gql`
  mutation deleteNotificationProfileAsset($id: ID!) {
    deleteNotificationProfileAsset(input: {id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_NOTIFICATION_PROFILE_SITES_MUTATION = gql`
  mutation updateNotificationProfileSites($notificationProfileId: ID!, $siteIds: [ID!]) {
    updateNotificationProfileSites(input: { notificationProfileId : $notificationProfileId, siteIds: $siteIds }) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_NOTIFICATION_PROFILE_SITE_MUTATION = gql`
  mutation deleteNotificationProfileSite($id: ID!) {
    deleteNotificationProfileSite(input: {id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_NOTIFICATION_PROFILE_ZONES_MUTATION = gql`
  mutation updateNotificationProfileZones($notificationProfileId: ID!, $zoneIds: [ID!]) {
    updateNotificationProfileZones(input: { notificationProfileId : $notificationProfileId, zoneIds: $zoneIds }) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_NOTIFICATION_PROFILE_ZONE_MUTATION = gql`
  mutation deleteNotificationProfileZone($id: ID!) {
    deleteNotificationProfileZone(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_CUSTOMER_WAYPOINT_MUTATION = gql`
  mutation createCustomerWaypoint($companyId: ID!, $name: String!, $address: String, $lat: Float, $lng: Float, $website: String, $telephone: String, $postcode: String, $defaultZoom: Int) {
    createCustomerWaypoint(input: { companyId: $companyId, name: $name, address: $address, lat: $lat, lng: $lng, website: $website,
        telephone: $telephone,
        postcode: $postcode
        defaultZoom: $defaultZoom}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_CUSTOMER_WAYPOINT_MUTATION = gql`
  mutation updateCustomerWaypoint($id: ID!, $companyId: ID!, $name: String!,  $address: String, $lat: Float, $lng: Float, $website: String, $telephone: String, $postcode: String, $defaultZoom: Int) {
    updateCustomerWaypoint(input: { id: $id, companyId: $companyId, name: $name, address: $address, lat: $lat, lng: $lng, website: $website,
        telephone: $telephone,
        postcode: $postcode
        defaultZoom: $defaultZoom }) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_CUSTOMER_WAYPOINT_MUTATION = gql`
  mutation deleteCustomerWaypoint($id: ID!) {
    deleteCustomerWaypoint(input: { id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_CREATE_BULK_CUSTOMER_WAYPOINT_MUTATION = gql`
  mutation createBulkCustomerWaypoints($customerWaypoints: [CreateCustomerWaypointInput!] ) {
    createBulkCustomerWaypoints(input: { customerWaypoints : $customerWaypoints}) {
      success
      message
      failedRecords {
        name
        address
        lat
        lng
        companyId
        website
        telephone
        postcode
        defaultZoom
      }
    }
  }
`


export const MANAGE_CREATE_DRIVER_MUTATION = gql`
  mutation createDriver($email: String!, $firstName: String!, $lastName: String!,$telephone: String, $companyId: ID!, $profilePictureFile: Upload, $assetId: ID ) {
    createDriver(input: { email: $email,firstName: $firstName, lastName: $lastName, telephone: $telephone, companyId: $companyId, profilePictureFile: $profilePictureFile, assetId: $assetId }) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_DRIVER_MUTATION = gql`
  mutation updateDriver($id: ID!, $email: String!, $firstName: String!, $lastName: String!,$telephone: String,  $companyId: ID!, $profilePictureFile: Upload, $assetId: ID ) {
    updateDriver(input: {id: $id, email: $email, firstName: $firstName, lastName: $lastName, telephone: $telephone, companyId: $companyId, profilePictureFile: $profilePictureFile, assetId: $assetId}) {
      success
      message
    }
  }
`


export const MANAGE_DELETE_DRIVER_MUTATION = gql`
  mutation deleteDriver($id: ID!) {
    deleteDriver(input: { id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_UPDATE_USER_PROFILE_MUTATION = gql`
  mutation updateUserProfile($id: ID!, $firstName: String!, $lastName: String!,$telephone: String, $profilePictureFile: Upload, $emailPasscodeEnabled: Boolean  ) {
    updateUserProfile(input: {id: $id,firstName: $firstName, lastName: $lastName, telephone: $telephone, profilePictureFile: $profilePictureFile, emailPasscodeEnabled: $emailPasscodeEnabled}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_COMPANY_DB_SETTING_MUTATION = gql`
  mutation createCompanyDbSetting($companyId: ID!, $tsConnString: String!, $schemaName: String!, $namespace: String, $isHanwellEnabled: Boolean!
    $hanwellConnString: String, $isKubeVisionEnabled: Boolean!, $isLorawanEnabled: Boolean!, $isMigratedToSematicsUnify: Boolean, $geofenceRadius: Int!) {
    createCompanyDbSetting(input: { companyId: $companyId, tsConnString: $tsConnString, schemaName: $schemaName, namespace: $namespace, isHanwellEnabled: $isHanwellEnabled, isMigratedToSematicsUnify: $isMigratedToSematicsUnify, hanwellConnString: $hanwellConnString, isKubeVisionEnabled: $isKubeVisionEnabled, isLorawanEnabled: $isLorawanEnabled, geofenceRadius: $geofenceRadius }) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_COMPANY_DB_SETTING_MUTATION = gql`
  mutation updateCompanyDbSetting($id: ID!, $companyId: ID!, $tsConnString: String!, $schemaName: String!, $namespace: String, $isMigratedToSematicsUnify: Boolean, $isHanwellEnabled: Boolean!
    $hanwellConnString: String, $isKubeVisionEnabled: Boolean!, $isLorawanEnabled: Boolean!, $geofenceRadius: Int!) {
    updateCompanyDbSetting(input: { id: $id, companyId: $companyId, tsConnString: $tsConnString, schemaName: $schemaName, namespace: $namespace, isHanwellEnabled: $isHanwellEnabled, isMigratedToSematicsUnify: $isMigratedToSematicsUnify, hanwellConnString: $hanwellConnString, isKubeVisionEnabled: $isKubeVisionEnabled, isLorawanEnabled: $isLorawanEnabled, geofenceRadius: $geofenceRadius}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_COMPANY_DB_SETTING_MUTATION = gql`
  mutation deleteCompanyDbSetting($id: ID!) {
    deleteCompanyDbSetting(input: {id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_ZONE_FLOOR_PLAN_ANNOTATION_MUTATION = gql`
  mutation updateZoneFloorPlanAnnotations($zoneId: ID!, $companyId: ID!, $annotations: [InputZoneFloorPlanAnnotation] ) {
    updateZoneFloorPlanAnnotations(input: {zoneId: $zoneId, companyId: $companyId, annotations: $annotations}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_CONTACT_MUTATION = gql`
  mutation createContact($email: String!, $name: String!,$telephone: String, $companyId: ID!) {
    createContact(input: { email: $email, name: $name, telephone: $telephone, companyId: $companyId}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_CONTACT_MUTATION = gql`
  mutation updateContact($id: ID!, $email: String!, $name: String!,$telephone: String, $companyId: ID!) {
    updateContact(input: {id: $id, email: $email, name: $name, telephone: $telephone, companyId: $companyId}) {
      success
      message
    }
  }
`


export const MANAGE_DELETE_CONTACT_MUTATION = gql`
  mutation deleteContact($id: ID!) {
    deleteContact(input: { id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_REPORT_SCHEDULE_MUTATION = gql`
   mutation createReportSchedule($name: String!, $reportNames: [String!], $frequency: [String!], $type: String,
   $companyId: ID!, $siteId: [ID!], $zoneId: [ID!], $assetId: [ID!]) {
     createReportSchedule(input: { name: $name, type: $type, reportNames: $reportNames, frequency: $frequency, companyId: $companyId, siteId: $siteId, zoneId: $zoneId, assetId: $assetId }) {
       success
       message
     }
   }
 `

export const MANAGE_UPDATE_REPORT_SCHEDULE_MUTATION = gql`
   mutation updateReportSchedule($id: ID!,  $name: String!, $reportNames: [String!], $frequency: [String!], $type: String, $siteId: [ID!], $zoneId: [ID!], $assetId: [ID!]) {
     updateReportSchedule(input: {id: $id, name: $name, type: $type, reportNames: $reportNames, frequency: $frequency, siteId: $siteId, zoneId: $zoneId, assetId: $assetId}) {
       success
       message
     }
   }
 `


export const MANAGE_DELETE_REPORT_SCHEDULE_MUTATION = gql`
   mutation deleteReportSchedule($id: ID!) {
     deleteReportSchedule(input: { id: $id}) {
       success
       message
     }
   }
 `


export const MANAGE_UPDATE_ASSET_OUT_OF_SERVICE_MUTATION = gql`
  mutation updateAssetOutOfService($id: ID!, $isOutOfService: Boolean! ) {
    updateAssetOutOfService(input: { id: $id, isOutOfService: $isOutOfService}) {
      success
      message
    }
  }
`


export const MANAGE_UPDATE_DEVICE_OUT_OF_SERVICE_MUTATION = gql`
  mutation updateDeviceOutOfService($id: ID!, $isOutOfService: Boolean! ) {
    updateDeviceOutOfService(input: { id: $id, isOutOfService: $isOutOfService}) {
      success
      message
    }
  }
`

export const MANAGE_APPROVE_DEVICE_CERTIFICATION_MUTATION = gql`
  mutation approveDeviceCertificate( $ids: [ID!]!, $comment: String!, $isApproved: Boolean!) {
    approveDeviceCertificate(input: {ids: $ids, comment: $comment, isApproved: $isApproved}) {
      success
      message
    }
  }
`


export const MANAGE_DELETE_DEVICE_CERTIFICATION_MUTATION = gql`
   mutation deleteDeviceCertificate($id: ID!) {
     deleteDeviceCertificate(input: { id: $id}) {
       success
       message
     }
   }
 `


export const MANAGE_CREATE_CONTROL_DEVICE_MUTATION = gql`
   mutation createHanwellControlDevice($controlDeviceName: String!,
    $controlDeviceType: Int!,
    $controlDeviceSerialNo: String!,
    $idGroup: ID!,
    $versions: String,
    $companyId: ID!) {
     createHanwellControlDevice(input: { controlDeviceName: $controlDeviceName, controlDeviceType: $controlDeviceType, controlDeviceSerialNo: $controlDeviceSerialNo, idGroup: $idGroup, versions: $versions, companyId: $companyId}) {
       success
       message
     }
   }
 `

export const MANAGE_UPDATE_CONTROL_DEVICE_MUTATION = gql`
   mutation updateHanwellControlDevice(
    $id: ID!,
    $controlDeviceName: String!,
    $controlDeviceType: Int!,
    $controlDeviceSerialNo: String!,
    $idGroup: ID!,
    $versions: String,
    $companyId: ID!) {
     updateHanwellControlDevice(input: {id: $id, controlDeviceName: $controlDeviceName, controlDeviceType: $controlDeviceType, controlDeviceSerialNo: $controlDeviceSerialNo, idGroup: $idGroup, versions: $versions, companyId: $companyId}) {
       success
       message
     }
   }
 `


export const MANAGE_DELETE_CONTROL_DEVICE_MUTATION = gql`
   mutation deleteHanwellControlDevice($id: ID!, $companyId: ID!) {
     deleteHanwellControlDevice(input: { id: $id,  companyId: $companyId}) {
       success
       message
     }
   }
 `


export const MANAGE_CREATE_CONTROL_DEVICE_GROUP_MUTATION = gql`
   mutation createHanwellControlDeviceGroup(
    $groupName: String!,
    $companyId: ID!) {
     createHanwellControlDeviceGroup(input: { groupName: $groupName, companyId: $companyId}) {
       success
       message
     }
   }
 `

export const MANAGE_UPDATE_CONTROL_DEVICE_GROUP_MUTATION = gql`
   mutation updateHanwellControlDeviceGroup(
    $id: ID!,
    $groupName: String!,
    $companyId: ID!) {
     updateHanwellControlDeviceGroup(input: {id: $id, groupName: $groupName, companyId: $companyId}) {
       success
       message
     }
   }
 `

export const MANAGE_DELETE_CONTROL_DEVICE_GROUP_MUTATION = gql`
   mutation deleteHanwellControlDeviceGroup($id: ID!, $companyId: ID!) {
     deleteHanwellControlDeviceGroup(input: { id: $id,  companyId: $companyId}) {
       success
       message
     }
   }
 `

export const MANAGE_SYNC_CONTROL_DEVICE_GROUP_SENSOR_SYNC_MUTATION = gql`
   mutation addNewDeviceSensorToControlDeviceGroup(
    $id: ID!,
    $sensors: [String!],
    $companyId: ID!) {
     addNewDeviceSensorToControlDeviceGroup(input: {id: $id,  sensors: $sensors, companyId: $companyId}) {
       success
       message
     }
   }
 `

export const MANAGE_DELETE_CONTROL_DEVICE_GROUP_SENSOR_MUTATION = gql`
   mutation deleteSensorFromControlDeviceGroup(
    $id: ID!,
    $sensorIds: [ID!],
    $companyId: ID!) {
     deleteSensorFromControlDeviceGroup(input: {id: $id,  sensorIds: $sensorIds, companyId: $companyId}) {
       success
       message
     }
   }
 `


export const MANAGE_UPDATE_SYSTEM_SETTING_MUTATION = gql`
   mutation updateSystemSetting(
    $id: ID!,
    $expiry: Int!,
    $lockout: Int!,
    $passwordHistoryCount: Int!
    ) {
     updateSystemSetting(input: {id: $id, expiry: $expiry, lockout: $lockout, passwordHistoryCount: $passwordHistoryCount}) {
       success
       message
       systemSetting {
              id
              expiry
              lockout
              passwordHistoryCount
              companyId
        }
     }
   }
 `

export const MANAGE_CREATE_DEFAULT_DEVICE_CONFIGURATION_MUTATION = gql`
  mutation createDefaultDeviceConfiguration($name: String!, $data: String!) {
    createDefaultDeviceConfiguration(input: { name: $name, data: $data}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_DEFAULT_DEVICE_CONFIGURATION_MUTATION = gql`
  mutation updateDefaultDeviceConfiguration($id: ID!, $name: String!, $data: String!) {
    updateDefaultDeviceConfiguration(input: {id: $id, name: $name, data: $data}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_DEFAULT_DEVICE_CONFIGURATION_MUTATION = gql`
  mutation deleteDefaultDeviceConfiguration($id: ID!) {
    deleteDefaultDeviceConfiguration(input: {id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_CREATE_LORAWAN_GATEWAY_MUTATION = gql`
  mutation createLorawanGateway($gatewayId: String!, $name: String!, $description: String,
  $latitude: Float, $longitude: Float, $altitude: Float, $defaultZoom: Float, $statsIntervalSecs: Int!, $companyId: ID!, $tags: String
  ) {
    createLorawanGateway(input: { gatewayId : $gatewayId, name: $name, description: $description, latitude: $latitude, longitude: $longitude, altitude: $altitude, defaultZoom: $defaultZoom, statsIntervalSecs: $statsIntervalSecs, companyId: $companyId, tags: $tags}) {
      id
      success
      message
    }
  }
`

export const MANAGE_UPDATE_LORAWAN_GATEWAY_MUTATION = gql`
  mutation updateLorawanGateway($id: ID!, $gatewayId: String!, $name: String!, $description: String,
  $latitude: Float, $longitude: Float, $altitude: Float, $defaultZoom: Float, $statsIntervalSecs: Int!, $companyId: ID!, $tags: String
  ) {
    updateLorawanGateway(input: { id: $id, gatewayId : $gatewayId, name: $name, description: $description, latitude: $latitude, longitude: $longitude, altitude: $altitude, defaultZoom: $defaultZoom, statsIntervalSecs: $statsIntervalSecs, companyId: $companyId, tags: $tags}) {
      id
      success
      message
    }
  }
`


export const MANAGE_DELETE_LORAWAN_GATEWAY_MUTATION = gql`
  mutation deleteLorawanGateway($id: ID!) {
    deleteLorawanGateway(input: { id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_INCIDENT_ROOT_CAUSE_MUTATION = gql`
  mutation createIncidentRootCauseType($name: String!, $companyId: ID!, $siteIds: [ID], $isCompanyGlobal: Boolean) {
    createIncidentRootCauseType(input: {name: $name, companyId: $companyId, siteIds: $siteIds,  isCompanyGlobal: $isCompanyGlobal}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_INCIDENT_ROOT_CAUSE_MUTATION = gql`
  mutation updateIncidentRootCauseType($id: ID!, $name: String!, $siteIds: [ID], $isCompanyGlobal: Boolean) {
    updateIncidentRootCauseType(input: {id: $id, name: $name, siteIds: $siteIds, isCompanyGlobal: $isCompanyGlobal}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_INCIDENT_ROOT_CAUSE_MUTATION = gql`
  mutation deleteIncidentRootCauseType($id: ID!) {
    deleteIncidentRootCauseType(input: { id: $id}) {
      success
      message
    }
  }
`


export const MANAGE_CREATE_INCIDENT_CORRECTIVE_ACTION_MUTATION = gql`
  mutation createIncidentCorrectiveActionType($name: String!, $companyId: ID!, $siteIds: [ID], $isCompanyGlobal: Boolean) {
    createIncidentCorrectiveActionType(input: {name: $name, companyId: $companyId, siteIds: $siteIds,  isCompanyGlobal: $isCompanyGlobal}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_INCIDENT_CORRECTIVE_ACTION_MUTATION = gql`
  mutation updateIncidentCorrectiveActionType($id: ID!, $name: String!, $siteIds: [ID], $isCompanyGlobal: Boolean) {
    updateIncidentCorrectiveActionType(input: {id: $id, name: $name, siteIds: $siteIds, isCompanyGlobal: $isCompanyGlobal}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_INCIDENT_CORRECTIVE_ACTION_MUTATION = gql`
  mutation deleteIncidentCorrectiveActionType($id: ID!) {
    deleteIncidentCorrectiveActionType(input: { id: $id}) {
      success
      message
    }
  }
`



export const MANAGE_CREATE_INCIDENT_PREVENTIVE_ACTION_MUTATION = gql`
  mutation createIncidentPreventiveActionType($name: String!, $companyId: ID!, $siteIds: [ID], $isCompanyGlobal: Boolean) {
    createIncidentPreventiveActionType(input: {name: $name, companyId: $companyId, siteIds: $siteIds,  isCompanyGlobal: $isCompanyGlobal}) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_INCIDENT_PREVENTIVE_ACTION_MUTATION = gql`
  mutation updateIncidentPreventiveActionType($id: ID!, $name: String!, $siteIds: [ID], $isCompanyGlobal: Boolean) {
    updateIncidentPreventiveActionType(input: {id: $id, name: $name, siteIds: $siteIds, isCompanyGlobal: $isCompanyGlobal}) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_INCIDENT_PREVENTIVE_ACTION_MUTATION = gql`
  mutation deleteIncidentPreventiveActionType($id: ID!) {
    deleteIncidentPreventiveActionType(input: { id: $id}) {
      success
      message
    }
  }
`

export const MANAGE_CREATE_NOTIFICATION_WEBHOOK_MUTATION = gql`
  mutation createNotificationWebhook(
    $companyId: ID!,
    $name: String!,
    $authUrl: String!,
    $url: String!,
    $secretKey: String,
    $description: String,
    $payload: String,
  ) {
    createNotificationWebhook(
      input: {
        companyId: $companyId
        name: $name
        authUrl: $authUrl,
        url: $url
        secretKey: $secretKey
        description: $description
        payload: $payload
      }
    ) {
      success
      message
    }
  }
`

export const MANAGE_UPDATE_NOTIFICATION_WEBHOOK_MUTATION = gql`
  mutation updateNotificationWebhook(
    $id: ID!,
    $companyId: ID!,
    $name: String!,
    $authUrl: String!,
    $url: String!,
    $secretKey: String,
    $description: String,
    $payload: String,
  ) {
    updateNotificationWebhook(
      input: {
        id: $id
        companyId: $companyId
        name: $name
        authUrl: $authUrl
        url: $url
        secretKey: $secretKey
        description: $description
        payload: $payload
      }
    ) {
      success
      message
    }
  }
`

export const MANAGE_DELETE_NOTIFICATION_WEBHOOKS_MUTATION = gql`
  mutation deleteNotificationWebhook($id: ID!) {
    deleteNotificationWebhook(input: { id: $id }) {
      success
      message
    }
  }
`
